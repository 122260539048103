var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _c(
              "vs-button",
              {
                staticClass: "rounded-lg xs:w-full sm:w-auto",
                attrs: {
                  to: {
                    name: "edit-webcam",
                    params: { webcamUuid: "", webcam: {} }
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("resorts.webcams.addwebcam")) + " ")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _c("Datatable", {
              attrs: {
                config: _vm.datatableConfig,
                elements: _vm.webcams,
                stripe: true,
                draggable: true,
                sst: true,
                pagination: _vm.pagination
              },
              on: {
                action: _vm.action,
                sort: _vm.sort,
                filter: _vm.filter,
                checkbox: _vm.publish
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }